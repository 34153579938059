import React, { useEffect, useState } from "react";
import useClasses from './UseClass'
import { useOktaAuth } from '@okta/okta-react';

import axios from "axios";
import {
  Typography,
  TextField,
  Button,
  FormControl,
  Divider,
  CircularProgress,
  Box,
  Stack,
  Paper,
  useTheme
} from "@mui/material";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import HistoryIcon from "@mui/icons-material/History";
import SearchIcon from '@mui/icons-material/Search';
import DescriptionIcon from '@mui/icons-material/Description';

import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import AiSummarization from './components/AiSummarization';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  RadialLinearScale, // Import the scale used by radar charts
  TimeScale,
  Tooltip,
  Legend,
  Title,
} from 'chart.js';

import { Bar, Line } from "react-chartjs-2";


import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// import AnimatedNumber from "animated-number-react";
import LinearProgress from '@mui/material/LinearProgress';
import Autocomplete from '@mui/material/Autocomplete';

import stockfile from "./data/stock_list.json";
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import Comments from './components/Comments';
import TwitterFeed from './components/TwitterFeed';
import NewsFeed from './components/NewsFeed';
import { styles } from './styles/stockStyles';
import { useStockData } from './hooks/useStockData';
import LaunchIcon from '@mui/icons-material/Launch';

import PriceRangeBar from './components/PriceRangeBar';
import PersonIcon from '@mui/icons-material/Person';
import BarChartIcon from '@mui/icons-material/BarChart';
import ConstructionIcon from '@mui/icons-material/Construction';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CustomDataTable from "./components/CustomDataTable";

const calculateCorrelation = require("calculate-correlation");

// Register all necessary components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  RadialLinearScale,  // This is required for Radar charts
  TimeScale,
  Tooltip,
  Legend,
  Title
);

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" marginTop={1} alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} color="secondary" />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="initial">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (

    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}


export default function StockChart() {
  const classes = useClasses(styles);
  const { authState } = useOktaAuth();
  const user_name = authState?.idToken?.claims?.name;
  const theme = useTheme();

  const apiKey = process.env.REACT_APP_RAPIDAPI_YAHU_KEY;
  const apiHost = "apidojo-yahoo-finance-v1.p.rapidapi.com";
  const [twohundreprice, setTwohundreprice] = useState(null);
  const [stockList, setStockList] = useState([]);
  const [tabvalue, setTabvalue] = React.useState(0);
  // const [tabvalueO, setTabvalueO] = React.useState(0);
  const [tabvalueO0, setTabvalueO0] = React.useState(0);
  // const [tabvalueO01, setTabvalueO01] = React.useState(0);
  const [Keyword, setKeyword] = useState(null);
  const newsKeyword = Keyword
  const [symbol, setSymbol] = useState("");
  const [mySymbolName, setMySymbolName] = useState("");
  const [priceHistoryArray, setPriceHistoryArray] = useState({});
  const [alternativeHigh, setAlternativeHigh] = useState(0);
  const [alternativeLow, setAlternativeLow] = useState(0);
  const [histPriceOpenAiArray, setHistPriceOpenAiArray] = useState("");
  // const [inxPricePerc, setInxPricePerc] = useState(null);
  const [stockSPCorrelation, setStockSPCorrelation] = useState(null);
  const [stockCorrelationNasq, setStockCorrelationNasq] = useState(null);
  const [lineHistData, setLineHistData] = useState([]);
  const [barHistData, setBarHistData] = useState([]);
  const [histDataArray, setHistDataArray] = useState([]);

  const hisDataColumns = [
    { name: "Date", selector: (row) => row.date },
    { name: "Closing Price", selector: (row) => row.closingPrice },
    { name: "Volume", selector: (row) => row.volume },
  ];

  const { apiData, twitterData, profileData, profileName, sector, marketCap, employeeNum, homepageurl, logourl,
    price, volume, changepct, deltaIndicator, lowValue, highValue, dataNews, stockAnalyzer0, stockAnalyzer1,
    sharesOutstanding, loading, qRevenuData, yRevenuData, yStringText, qStringText
  } = useStockData(symbol, mySymbolName);


  const handleChange = (event, newValue) => {
    setTabvalue(newValue);
  };
  // const handleChangeO = (event, newValue) => {
  //   setTabvalueO(newValue);
  // };
  const handleChangeO0 = (event, newValue) => {
    setTabvalueO0(newValue);
  };
  // const handleChangeO01 = (event, newValue) => {
  //   setTabvalueO01(newValue);
  // };



  // Load stockList once on mount
  useEffect(() => {
    const stock_arr = Object.keys(stockfile).map((key) => ({
      label: stockfile[key]["Symbol"],
      value: stockfile[key]["Name"],
    }));
    setStockList(stock_arr);
  }, []);


  // Custom filter function: filters and sorts
  const filterOptionsCustom = (options, { inputValue }) => {
    const input = inputValue.trim().toLowerCase();
    if (!input) return [];
    // Filter options that include the input text
    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(input)
    );
    // Sort alphabetically (case-insensitive), then by length (shortest first)
    filtered.sort((a, b) => {
      const aLabel = a.label.toLowerCase();
      const bLabel = b.label.toLowerCase();
      if (aLabel < bLabel) return -1;
      if (aLabel > bLabel) return 1;
      return a.label.length - b.label.length;
    });
    return filtered;
  };



  // eslint-disable-next-line


  function searchStock(e) {
    e.preventDefault();
    var mySymbol = document.getElementById("standardStock").value;
    setSymbol(mySymbol)
    // Search for the stock in the stockfile using Object.values
    var mySymbolObject = Object.values(stockfile).find(stock => stock.Symbol === mySymbol);
    // var mySymbolName = mySymbolObject.Name
    setMySymbolName(mySymbolObject.Name)
    setKeyword(mySymbol.toUpperCase())



    const fetchStockData = async () => {
      const optionsHistory = {
        method: 'GET',
        url: 'https://yh-finance.p.rapidapi.com/market/get-charts',
        params: {
          symbol: mySymbol,
          interval: '1d',
          range: '5y',
          region: 'US',
          comparisons: '^GSPC,^IXIC'

        },
        headers: {
          'x-rapidapi-key': apiKey,
          'x-rapidapi-host': apiHost
        }
      };

      await axios.request(optionsHistory).then(function (responseHistory) {

        let histDate = [];
        let histPrice = [];
        let histSP500 = []; //^GSPC
        let histNasq = []; //^IXIC
        let hisArr = [];
        let hisTrans = [];
        let hisVolumes = [];

        const HistPriceOpenAiArray = {};

        // get multiplier of comparision
        var sp500Multiplier = responseHistory.data.chart.result[0].comparisons[0].close[0] / responseHistory.data.chart.result[0].indicators.adjclose[0].adjclose[0] // 10
        var nasdqMultiplier = responseHistory.data.chart.result[0].comparisons[1].close[0] / responseHistory.data.chart.result[0].indicators.adjclose[0].adjclose[0] // 50

        const prices = responseHistory.data.chart.result[0].indicators.adjclose[0].adjclose;
        const volumes = responseHistory.data.chart.result[0].indicators.quote[0].volume;

        // epoch time converter
        for (
          var his = 0;
          his < responseHistory.data.chart.result[0].timestamp.length;
          his++
        ) {
          var objhis = responseHistory.data.chart.result[0].timestamp[his];
          const date = new Date(objhis * 1000);
          const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
          const closingPrice = parseFloat(prices[his].toFixed(2));// responseHistory.data.chart.result[0].indicators.adjclose[0].adjclose[his].toFixed(2);

          // var year = date.getFullYear();
          // var month = date.getMonth() + 1;
          // var day = date.getDate();
          histDate.push(formattedDate); // x-axis Date YYYY-MM-DD year + "-" + month + "-" + day
          histPrice.push(closingPrice); // daily closed price 
          histSP500.push((responseHistory.data.chart.result[0].comparisons[0].close[his] / sp500Multiplier).toFixed(2)); // daily high price
          histNasq.push((responseHistory.data.chart.result[0].comparisons[1].close[his] / nasdqMultiplier).toFixed(2)); // daily low price
          hisTrans.push(((responseHistory.data.chart.result[0].indicators.quote[0].low[his] + responseHistory.data.chart.result[0].indicators.quote[0].high[his] + responseHistory.data.chart.result[0].indicators.quote[0].open[his] + responseHistory.data.chart.result[0].indicators.quote[0].close[his]) * responseHistory.data.chart.result[0].indicators.quote[0].volume[his] / 4).toFixed(2))
          hisVolumes.push(responseHistory.data.chart.result[0].indicators.quote[0].volume[his]);

          HistPriceOpenAiArray[formattedDate] = {
            closingPrice,
            volume: volumes[his],
          };


          hisArr.push({
            date: formattedDate, //year + "-" + month + "-" + day,
            close: closingPrice,
            sp500: (responseHistory.data.chart.result[0].comparisons[0].close[his] / sp500Multiplier).toFixed(2),
            nasdq: (responseHistory.data.chart.result[0].comparisons[1].close[his] / nasdqMultiplier).toFixed(2),
            trans: ((responseHistory.data.chart.result[0].indicators.quote[0].low[his] + responseHistory.data.chart.result[0].indicators.quote[0].high[his] + responseHistory.data.chart.result[0].indicators.quote[0].open[his] + responseHistory.data.chart.result[0].indicators.quote[0].close[his]) * responseHistory.data.chart.result[0].indicators.quote[0].volume[his] / 4).toFixed(2)
          })
        }

        const stringifiedHistPriceOpenAiArray = JSON.stringify(HistPriceOpenAiArray, null, 2);
        setHistPriceOpenAiArray(stringifiedHistPriceOpenAiArray);

        // Usage
        const movingAverage200Days = calculateMovingAverage(hisArr, 200);
        setTwohundreprice(movingAverage200Days.movingAverage.toFixed(2));
        console.log("twohundreprice: ", twohundreprice);

        // Convert object into an array
        // Convert object into an array
        const histDataArray = Object.keys(HistPriceOpenAiArray).map(date => ({
          date,
          ...HistPriceOpenAiArray[date],
        }));
        // console.log("####### histDataArray:" + JSON.stringify(histDataArray, null, 2));

        setHistDataArray(histDataArray)

        //histDate, histPrice, hisVolumes
        var x = histPrice.map(Number)
        var y = histSP500.map(Number)
        var z = histNasq.map(Number)

        if (histPrice && histPrice.length > 100) {
          // Get the last 250 values (or fewer if histPrice.length < 250)
          const last250 = histPrice.slice(-250);
          // Calculate max and min values
          const maxValue = Math.max(...last250);
          const minValue = Math.min(...last250);
          setAlternativeHigh(maxValue);
          setAlternativeLow(minValue);
        }


        // var stockBasedPrice = x[x.length - 1]
        // var spBasedPrice = y[y.length - 1]
        // var nasdqBasedPrice = z[z.length - 1]
        // var inxBasedPrice = (spBasedPrice + nasdqBasedPrice) / 2
        // setInxPrice(inxBasedPrice)
        // setInxPricePerc((((inxBasedPrice / stockBasedPrice) - 1) * 100).toFixed(0))

        const correlationSP = calculateCorrelation(x, y);
        const correlationNasdaq = calculateCorrelation(x, z);

        setStockSPCorrelation(correlationSP.toFixed(2))
        setStockCorrelationNasq(correlationNasdaq.toFixed(2))


        setPriceHistoryArray(hisArr) // Price Tab of Price Line Chart

        setBarHistData({
          labels: histDate,
          datasets: [
            {
              type: 'line',
              label: 'Price',
              borderColor: '#338796',
              borderWidth: 1,
              fill: false,
              data: histPrice,
              yAxisID: 'y-axis-1',

            },
            {
              type: 'bar',
              label: mySymbol + " Transaction($)",
              data: hisTrans,
              backgroundColor: '#FF9671',
              yAxisID: 'y-axis-2',

            }
          ],
        });

        setLineHistData({
          labels: histDate,  //.reverse(),
          datasets: [
            {
              label: mySymbol,
              data: histPrice, //.reverse(),
              fill: true,
              borderColor: "#338796",
            },
            {
              label: "SP500",
              data: histSP500, //.reverse(),
              fill: false,
              borderColor: "#4fad61",

            },
            {
              label: "NASDQ",
              data: histNasq, //.reverse(),
              fill: false,
              borderColor: "#ffa600",
            }
          ],
        });

      }).catch(function (error) {
        console.error("responseHistory: ", error);
      });

    };
    fetchStockData();

  }


  function calculateMovingAverage(data, days) {
    if (data.length < days) {
      console.error('Insufficient data to calculate the moving average.');
      return null;
    }

    // Sort data by ascending date (oldest first)
    data.sort((a, b) => new Date(a.date) - new Date(b.date));

    // Select the most recent `days` entries
    const recentData = data.slice(-days);

    const sum = recentData.reduce((total, day) => total + day.close, 0);
    const avg = sum / days;

    return {
      date: recentData[recentData.length - 1].date,
      movingAverage: avg,
    };
  }

  const content0 = {
    role: "developer",
    content: "You are a Financial Analyst."
  };

  const content1 = {
    role: "user",
    content: `Analyze the provided data to recommend mid- to long-term buying and selling price ranges for ${symbol} (${mySymbolName}).
      Company Overview: ${profileData}

      Current Price: $${price}; 
      Two Hundred Day Average: $${twohundreprice};

      Fundamental Analysis:
      - Annual Performance: ${yStringText}
      - Quarterly Performance: ${qStringText}
      - Key Financial Metrics: ${stockAnalyzer0}; ${stockAnalyzer1}

      Technical Analysis:
      Apply relevant indicators including RSI, MACD, Bollinger Bands, Stochastic Oscillator, and Moving Averages, if available.

      Historical Data (Closing Price & Volume, YYYY-MM-DD):
      ${histPriceOpenAiArray}

      Consider the current international political and economic environment in your analysis.

      Clearly outline your reasoning and provide numerical ranges for:
      - Recommended Buying Prices
      - Recommended Selling Prices
      - Fair Value Price
      `
  };

  const changeHistoryRange = async (xdate) => {
    var today = new Date();
    var oneMonthAgo = new Date().setDate(today.getDate() - xdate)
    var lineChartDate = [];

    for (
      var MonthL = 0;
      MonthL < priceHistoryArray.length;
      MonthL++
    ) {

      if (new Date(priceHistoryArray[MonthL].date).getTime() > oneMonthAgo) {
        lineChartDate.push(priceHistoryArray[MonthL]);
      }
    }

    var datelist = [];
    var closelist = [];
    var sp500list = [];
    var nasdqlist = [];
    var translist = [];

    var sp500MultiplierCut = lineChartDate[0].close / lineChartDate[0].sp500
    var nasdqMultiplierCut = lineChartDate[0].close / lineChartDate[0].nasdq


    for (
      var i = 0;
      i < lineChartDate.length;
      i++) {
      datelist.push(lineChartDate[i].date)
      closelist.push(lineChartDate[i].close)
      sp500list.push((lineChartDate[i].sp500 * sp500MultiplierCut).toFixed(2))
      nasdqlist.push((lineChartDate[i].nasdq * nasdqMultiplierCut).toFixed(2))
      translist.push(lineChartDate[i].trans)
    }
    //--------------get correlation data array to compare with indexes------------------

    var x = closelist.map(Number)
    var y = sp500list.map(Number)
    var z = nasdqlist.map(Number)
    //get last trade day price to compare with indexes
    // var stockBasedPrice = x[x.length - 1]
    // var spBasedPrice = y[y.length - 1]
    // var nasdqBasedPrice = z[z.length - 1]
    // var inxBasedPrice = (spBasedPrice + nasdqBasedPrice) / 2
    // setInxPricePerc((((inxBasedPrice / stockBasedPrice) - 1) * 100).toFixed(0))

    const correlationSP = calculateCorrelation(x, y);
    const correlationNasdaq = calculateCorrelation(x, z);


    setStockSPCorrelation(correlationSP.toFixed(2))
    setStockCorrelationNasq(correlationNasdaq.toFixed(2))
    //--------------------------------------------------

    setBarHistData({
      labels: datelist,  //.reverse(),
      datasets: [
        {
          type: 'line',
          label: 'Price',
          borderColor: '#338796',
          borderWidth: 1,
          fill: false,
          data: closelist,
          yAxisID: 'y-axis-1',

        },
        {
          type: 'bar',
          label: Keyword + " Transaction($)",
          data: translist, //.reverse(),
          backgroundColor: '#FF9671',
          yAxisID: 'y-axis-2',

        }
      ],
    });

    setLineHistData({
      labels: datelist, // lineChartDate, histDate.reverse(),
      datasets: [
        {
          label: Keyword,
          data: closelist, //.reverse(),
          fill: true,
          borderColor: "#338796",


        },
        {
          label: "SP500",
          data: sp500list, //.reverse(),
          fill: false,
          borderColor: "#4fad61",

        },
        {
          label: "Nasdq",
          data: nasdqlist, //.reverse(),
          fill: false,
          borderColor: "#ffa600",

        }
      ],
    });

  };

  return (
    <div>
      <FormControl
        style={{
          display: "block",
          textAlign: 'left',
          margin: '10px',
        }}
      >


        <Autocomplete
          id="standardStock"
          freeSolo
          options={stockList}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.label || ""
          }
          filterOptions={filterOptionsCustom}
          renderOption={(props, option) => {
            const { key, ...rest } = props;
            return (
              <li key={key} {...rest}>
                {option.label}&nbsp;&nbsp;&nbsp;{option.value}
              </li>
            );
          }}
          defaultValue={newsKeyword}
          style={{ maxWidth: 800, minWidth: 300, marginBottom: 10 }}
          renderInput={(params) => <TextField {...params} label="Stock Symbol" />}
        />



        {authState?.isAuthenticated && authState?.idToken && user_name === "Mingfei JIANG" ? (
          <Button
            style={{ marginBottom: 12 }}
            onClick={searchStock}
            variant="contained"
            color="primary"
          >
            <SearchIcon /> Search
          </Button>
        ) : (
          <div>
            <Button
              style={{ marginBottom: 12 }}
              onClick={searchStock}
              variant="contained"
              color="primary"
              disabled
            >
              <SearchIcon /> Search
            </Button>
            <Typography>
              <ErrorOutlineIcon />&nbsp; Your Login Has Been Expired, Please Refresh Page or Login Again
            </Typography>
          </div>
        )}


      </FormControl>


      {profileName ? (<div> <Divider /><div style={{ display: 'flex', alignItems: 'center' }}>
        <h4 style={{ margin: 0 }}>{profileName}</h4>
        <a
          href={homepageurl}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.tabs}
          style={{ marginLeft: '8px' }}
        >
          <LaunchIcon />
        </a>
      </div>
        {logourl && (
      <Box
        sx={{
          width: 150,
          height: 150,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.palette.mode === 'dark' ? '#2e2e2e' : '#f4f4f4',
          borderRadius: 2,
          boxShadow: 1,
        }}
      >
        <img
          src={logourl}
          alt="Logo"
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
          }}
        />
      </Box>
    )}

      </div>) : null}


      {price > 0 ? (<Box sx={{ display: 'flex', alignItems: 'baseline', gap: 2 }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 'bold', color: 'primary.main' }}
        >
          ${price}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >
          Vol: {volume}
        </Typography>
      </Box>
      ) : null}



      {deltaIndicator === "up" ? (
        <Typography style={{ marginTop: "1em", color: "rgba(6, 201, 42, 0.9)" }}>
          <ArrowUpwardIcon /> {changepct}
        </Typography>
      ) : deltaIndicator === "down" ? (
        <Typography style={{ marginTop: "1em", color: "rgba(227, 59, 109, 0.9)" }}>
          <ArrowDownwardIcon /> {changepct}
        </Typography>
      ) : null}

      {alternativeLow > 0 ? <PriceRangeBar low={alternativeLow} high={alternativeHigh} current={price} /> : highValue > 0 ? (<PriceRangeBar low={lowValue} high={highValue} current={price} />) : null}


      {lineHistData.length === 0 ? (null) :
        <div>
          <Button style={{ marginBottom: 12 }} onClick={() => changeHistoryRange(7)}>
            5D
          </Button>
          <Button style={{ marginBottom: 12 }} onClick={() => changeHistoryRange(30)}>
            1M
          </Button>
          <Button style={{ marginBottom: 12 }} onClick={() => changeHistoryRange(90)}>
            3M
          </Button>
          <Button style={{ marginBottom: 12 }} onClick={() => changeHistoryRange(180)}>
            6M
          </Button>
          <Button style={{ marginBottom: 12 }} onClick={() => changeHistoryRange(360)}>
            1Y
          </Button>
          <Button style={{ marginBottom: 12 }} onClick={() => changeHistoryRange(1080)}>
            3Y
          </Button>
          <Button style={{ marginBottom: 12 }} onClick={() => changeHistoryRange(1800)}>
            Max
          </Button>
        </div>

      }


      {lineHistData.length === 0 ? (null) :
        <div className={classes.tabs}>
          <AppBar position="static" color="default">
            <Tabs
              value={tabvalueO0}
              onChange={handleChangeO0}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Price" {...a11yProps(0)} />
              <Tab label="Transaction" {...a11yProps(1)} />

            </Tabs>
          </AppBar>


          <TabPanel value={tabvalueO0} index={0} dir="x">
            <div className={classes.chart}>
              <Line
                data={lineHistData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    tooltip: { mode: "index", intersect: false },
                    title: {
                      display: true,
                      text: "Price Chart",
                      font: { size: 12 },
                    },
                  },
                  elements: {
                    point: {
                      radius: 0,
                    },
                  },
                  hover: { mode: "nearest", intersect: true },
                  scales: {
                    x: {
                      type: "time",
                      time: {
                        unit: "day",
                        parser: "yyyy-M-d", // This matches dates like "2025-3-5"
                        displayFormats: { day: "yyyy-MM-dd" },
                      },
                    },
                  },
                }}
              />

            </div>
          </TabPanel>
          <TabPanel value={tabvalueO0} index={1} dir="x">
            <div className={classes.chart}>
              <Bar
                data={barHistData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    tooltip: { mode: "index", intersect: false },
                    title: {
                      display: true,
                      text: "Transaction",
                      font: { size: 12 },
                    },
                  },
                  hover: { mode: "nearest", intersect: true },
                  scales: {
                    x: {
                      type: "time",
                      time: {
                        unit: "day",
                        parser: "yyyy-M-d", // matches dates like "2020-3-6"
                        displayFormats: { day: "yyyy-MM-dd" },
                      },
                    },
                    "y-axis-1": {
                      type: "linear",
                      display: true,
                      position: "left",
                    },
                    "y-axis-2": {
                      type: "linear",
                      display: true,
                      position: "right",
                      grid: { drawOnChartArea: false },
                    },
                  },
                }}
              />



            </div>
          </TabPanel>
        </div>
      }




      {profileData ? (<div>
        <Typography>
          <ConstructionIcon />&nbsp;Industry: {sector}
        </Typography>
        <Typography>
          {profileData}
        </Typography>
        <Divider />
        <Typography><PersonIcon />&nbsp;Employee Number: {employeeNum}</Typography>
      </div>) : null}

      {twohundreprice && (
        <div>
          <HistoryIcon /> &nbsp;200-Day Avg: ${twohundreprice} &nbsp;
          <span
            style={{
              marginTop: "1em",
              color: (price / twohundreprice - 1) >= 0 ? "rgba(6, 201, 42, 0.9)" : "rgba(255, 0, 0, 0.9)",
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <TrendingUpIcon />
            &nbsp;
            {`${(price / twohundreprice - 1) >= 0 ? "+" : ""}${((price / twohundreprice - 1) * 100).toFixed(2)}% compared to the 200-day average`}
          </span>
        </div>
      )}

      {stockAnalyzer0 && (
        <Paper elevation={3} sx={{ padding: 2, borderRadius: 2, backgroundColor: 'rgba(247,247,247,0.2)' }}>
          <Stack spacing={2}>
            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', color: 'primary' }}>
              <BarChartIcon sx={{ mr: 1 }} /> {stockAnalyzer0}
            </Typography>
            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', color: 'primary' }}>
              <AttachMoneyIcon sx={{ mr: 1 }} /> Yearly Performance: {yStringText}
            </Typography>
            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', color: 'primary' }}>
              <AttachMoneyIcon sx={{ mr: 1 }} /> Quarterly Performance: {qStringText}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {stockAnalyzer1}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <MonetizationOnIcon sx={{ mr: 1 }} />
              <Typography variant="body1" color="text.secondary">
                Market Cap: {marketCap} (~{((marketCap / 2034000000000) * 100).toFixed(2)}% of Google)
              </Typography>
              <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center',color:"text.secondary" }}>
              &nbsp;<AutoGraphIcon sx={{ mr: 1 }} /> S&P Index Correlation: {stockSPCorrelation}
              </Typography>
              <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center',color:"text.secondary" }}>
              &nbsp;<AutoGraphIcon sx={{ mr: 1 }} /> NASDAQ Index Correlation: {stockCorrelationNasq}
              </Typography>
            </Box>
          </Stack>
        </Paper>
      )}

      {histPriceOpenAiArray !== "" && symbol !== "" && price !== 0 && yStringText !== "" && qStringText !== "" && twohundreprice ? (<AiSummarization
        content0={content0}
        content1={content1}
        input={symbol}
        user_name={user_name}
        Descriptions={"$" + price}
        Brand={symbol}
      />) : (
        null)}

      {qRevenuData.datasets ? (
        <div>
          <div className={classes.tabs}>
            <AppBar position="static" color="default">
              <Tabs
                value={tabvalue}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Quarterly" {...a11yProps(0)} />
                <Tab label="Yearly" {...a11yProps(1)} />
              </Tabs>
            </AppBar>


            <TabPanel className={classes.chart} value={tabvalue} index={0} dir="x">
              <Bar
                data={qRevenuData}
                height={280}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    title: {
                      display: true,
                      text: "Quarterly Revenue & Profit",
                      font: { size: 12 },
                    },
                  },
                  scales: {
                    x: { ticks: { maxRotation: 90 } },
                    y: { beginAtZero: true },
                  },
                }}
              />
            </TabPanel>

            <TabPanel className={classes.chart} value={tabvalue} index={1} dir="x">
              <Bar
                data={yRevenuData}
                height={280}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    title: {
                      display: true,
                      text: "Yearly Revenue & Profit",
                      font: { size: 12 },
                    },
                  },
                  scales: {
                    x: {
                      ticks: { maxRotation: 90 },
                    },
                    y: {
                      beginAtZero: true,
                    },
                  },
                }}
              />

            </TabPanel>
          </div>


        </div>
      ) : (
        <div></div>
      )}

      {apiData && (
        <div>
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <Divider
              sx={{
                width: '50%',
                borderColor: 'primary.main',
                borderWidth: 2
              }}
            />
          </Box>
          <h6>
            <DescriptionIcon />&nbsp;{mySymbolName} News
          </h6>
          <NewsFeed newsData={dataNews} />
        </div>
      )}

      {twitterData && (
        <TwitterFeed twitterData={twitterData} />
      )}

      {apiData && (
        <Comments comments={apiData} classes={classes} />
      )}

      {histDataArray != [] && symbol !== "" && (<CustomDataTable title="Historical Price Data" columns={hisDataColumns} data={histDataArray} />)}

      {loading && (
        <div style={overlayStyle}>
          <CircularProgress style={spinnerStyle} />
        </div>
      )}

    </div>
  );
}
// Styles for overlay and spinner
const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const spinnerStyle = {
  color: "#ffffff",
};
