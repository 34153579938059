import React, { useState, useEffect } from 'react';
import OpenAI from 'openai';
import axios from 'axios';
import {
  Button,
  Paper,
  Typography,
  CircularProgress,
  List,
  ListItem,
  useTheme
} from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const formatBold = (text) => {
  const boldRegex = /\*\*(.*?)\*\*/g;
  const parts = [];
  let lastIndex = 0;
  let match;

  while ((match = boldRegex.exec(text)) !== null) {
    const [fullMatch, boldText] = match;
    const { index } = match;

    if (lastIndex < index) {
      parts.push(text.slice(lastIndex, index));
    }
    parts.push(<strong key={index}>{boldText}</strong>);
    lastIndex = index + fullMatch.length;
  }

  if (lastIndex < text.length) {
    parts.push(text.slice(lastIndex));
  }

  return parts;
};

const renderSummaryText = (text) => {
  const lines = text.split('\n');
  const elements = [];

  lines.forEach((line, index) => {
    if (line.startsWith('###')) {
      elements.push(
        <Typography key={index} variant="h6" sx={{ mt: 2, mb: 1 }}>
          {line.replace('###', '').trim()}
        </Typography>
      );
    } else if (/^\d+\.\s/.test(line)) {
      elements.push(
        <Typography key={index} variant="body1" sx={{ mt: 1, whiteSpace: 'pre-wrap' }}>
          {formatBold(line)}
        </Typography>
      );
    } else if (line.startsWith('- ')) {
      elements.push(
        <List key={index} sx={{ pl: 2 }}>
          <ListItem sx={{ display: 'list-item', py: 0 }}>
            <Typography variant="body2">{formatBold(line.substring(2))}</Typography>
          </ListItem>
        </List>
      );
    } else {
      elements.push(
        <Typography key={index} variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
          {formatBold(line)}
        </Typography>
      );
    }
  });

  return elements;
};

const AiSummarization = ({ content0, content1, input, user_name, Descriptions, Brand }) => {
  const [summary, setSummary] = useState('');
  const [loading, setLoading] = useState(false);
  const [lastSummarizedInput, setLastSummarizedInput] = useState("");
  const theme = useTheme();

  const runSummarization = async () => {
    if (!input) return;
    setLoading(true);
    try {
      const openai = new OpenAI({
        apiKey: process.env.REACT_APP_OPENAIAPI_KEY,
        dangerouslyAllowBrowser: true,
      });

      const response = await openai.chat.completions.create({
        model: 'gpt-4o',
        messages: [content0, content1],
        store: true,
      });
      const summaryText = response.choices[0].message.content;
      setSummary(summaryText);

      if (summaryText !== "") {
        axios
          .post("https://20211205t040925-dot-my-comment-api-dot-mingfei-test-account.appspot.com/api/comments", {
            comment_name: summaryText,
            user_name: user_name,
            Rating: 0,
            Description: Descriptions,
            Brand: Brand,
          })
          .then(() => {
            console.log("Summarization is saved");
          })
          .catch((error) => {
            console.log("Error posting comment:", error.response);
          });
      }
      setLastSummarizedInput(input);
    } catch (error) {
      console.error("Error during AI Summarization:", error);
      setSummary("Error during AI Summarization");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (summary && lastSummarizedInput !== input) {
      setSummary("");
    }
  }, [input, lastSummarizedInput, summary]);

  const isButtonDisabled = loading || (summary && lastSummarizedInput === input);

  return (
    <div>
      {input && (
        <div style={{ marginTop: '20px' }}>
          <Button
            variant="contained"
            onClick={runSummarization}
            disabled={isButtonDisabled}
            color="secondary"
            sx={{ mt: 2 }}
          >
            {loading ? "Summarizing..." : (
              <>
                <AutoAwesomeIcon sx={{ mr: 1 }} />
                AI Summarization
              </>
            )}
          </Button>
        </div>
      )}

      {summary && (
        <Paper
          elevation={3}
          sx={{
            mt: 2,
            p: 2,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AutoAwesomeIcon sx={{ mr: 1 }} /> AI Summarization
          </Typography>
          {renderSummaryText(summary)}
        </Paper>
      )}

      {loading && (
        <div style={overlayStyle}>
          <CircularProgress style={spinnerStyle} />
        </div>
      )}
    </div>
  );
};

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const spinnerStyle = {
  color: "#ffffff",
};

export default AiSummarization;






